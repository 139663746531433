<template>
	<div class="linkage">
		<div>
			<el-select v-model="selectData.province" value-key="label" filterable size="small" @change="selectProvinceFun($event)" placeholder="请选择省份" :disabled="disabled">
				<el-option :value="item" :label="item.label" v-for="(item, index) in provinceList" :key="index"></el-option>
			</el-select>
		</div>
		<div>
			<el-select v-model="selectData.city" value-key="label" filterable size="small" @change="selectCityFun($event)" placeholder="请选择城市" :disabled="disabled">
				<el-option :value="item" :label="item.label" v-for="(item, index) in cityList" :key="index"></el-option>
			</el-select>
		</div>
		<!-- <div>
			<el-select v-model="selectData.area" value-key="label" filterable size="small" @change="selectAreaFun($event)" placeholder="请选择区县">
				<el-option :value="item" :label="item.label" v-for="(item, index) in areaList" :key="index"></el-option>
			</el-select>
		</div> -->
	</div>
</template>
<script>
	import cityData from '@/assets/json/cityData.json';
	export default {
		name: 'ChinaAreaData',
		props: {
			areaData: Object,
    		disabled: Boolean,
		},
		data() {
			return {
				// 整个省市县数据
				provinceList: cityData,
				// 被选中的市数据
				cityList: [],
				// 被选中的县数据
				areaList: [],
				selectData: {
					province: {},
					city: {},
					// area: {},
				}
			}
		},
		mounted() {},
		created() {
			// if(this.areaData){
			// 	this.selectData.province = this.areaData.province
			// 	this.selectData.city = this.areaData.city
			// }
			
			// console.log(this.provinceList.filter(data => data.label == "湖北省")[0].children); // 默认湖北省，获取市级列表
			if(this.areaData && this.areaData.province){
				this.selectData.province = this.areaData.province
				this.selectData.city = this.areaData.city
				this.cityList = this.provinceList.filter(data => data.label == "湖北省")[0].children
			}else{
				this.selectData.province = '湖北省'
				this.selectData.city = '武汉市'
				this.cityList = this.provinceList.filter(data => data.label == "湖北省")[0].children
			}
			
			// 新登记页面，是否是第二步预览信息，input为disabled
			// console.log(this.disabled);

			
			
		},
		methods: {
			// 省份 市 县联动
			selectProvinceFun(event) {
				console.log(event);
				
				if (event) {
					this.cityList = event.children
				} else {
					this.cityList = []
				}
				this.areaList = [];
				this.selectData.city = {};
				this.selectData.area = {};
				this.$emit('change', {value:event.label,laber:'province',selectData:this.selectData});
			},
			selectCityFun(event) {
				if (event) {
					this.areaList = event.area
				} else {
					this.areaList = []
				}
				this.selectData.area = {};
				this.$emit('change', {value:event.label,laber:'city',selectData:this.selectData});
			},
			selectAreaFun(event) {
				// this.selectData = {
				// 	area: event.label
				// };
				this.$emit('change', {value:event.label,laber:'area',selectData:this.selectData});
			}
		}
	}
</script>
<style lang="scss" scoped>
	.linkage {
		display: flex;
		justify-content: space-between;align-items: center;
		div{
			width: calc(110% / 2.4);
			height: 40px;
		}
		/deep/.el-input__inner{
			height: 40px;
			line-height: 40px;
		}
	}
	
</style>
